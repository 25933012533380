/**
 * Login page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Iframelogin from "../components/iframelogin"
import Layoutwithoutfooter from "../components/layoutwithoutfooter"
import Seo from "../components/seo"
import "../styles/pages/login.scss"

/* Page function declaration */
const LoginPage = ({ data }) => {
  return (
    <Layoutwithoutfooter>
      <Seo
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
        classbody="no-cookies"
      />

      <section className="section-login iframe-section">
        <h1 className="title-level-1">Login</h1>
        <Iframelogin />
      </section>

    </Layoutwithoutfooter>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "login"}) {
      seo {
        metaDesc
        title
      }
    }
  }
`
/* Export page informations */
export default LoginPage


