/**
 * Const element
 * Iframe Login component
 * Display the iframe of Login
 */
/* Import section */
import React from "react"

/* Declaration function */
const Iframelogin= (paramclinic) => {
  /** NEW VERSION ON DEV27 */
  /*var src = 'https://dev27.myca.io/hhweb/apps/authentication/'
  paramclinic["paramClinic"] ? (src += '?s=' + paramclinic["paramClinic"]) : (src += '')
  src += '#loginPortalConnect'*/

  /** OLD VERSION ON PROD */
  /*var src='https://platform.hellohealth.com/login/'
  paramclinic["paramClinic"] ? (src += '?s=' + paramclinic["paramClinic"]) : (src += '')
  src += '?stylesheet=patientportal#login'*/

  /** NEW VERSION ON PROD */
  var src = 'https://platform.hellohealth.com/hhweb/apps/authentication/'
  paramclinic["paramClinic"] ? (src += '?s=' + paramclinic["paramClinic"]) : (src += '')
  src += '#loginPortalConnect'

  return (
    <iframe className="iframe-login" name="login" src={src} scrolling="no" title="login"></iframe>
  )
}

/* Export function */
export default Iframelogin
